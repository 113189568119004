import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { User } from '@/types/Types'

export const AuthService = {
  registerUser (user: User): AxiosPromise {
    return ApiService({
      url: '/auth/register',
      data: user,
      method: 'post'
    })
  },

  registerUserByInvitation (user: User): AxiosPromise {
    return ApiService({
      url: '/auth/register/invitation/' + user.invitationCode,
      data: user,
      method: 'post'
    })
  },

  requestVerificationForUser (email: string): AxiosPromise {
    return ApiService({
      url: '/auth/request-verify-token',
      data: { email: email },
      method: 'post'
    })
  },

  sendPasswordResetLink (email: string): AxiosPromise {
    return ApiService({
      url: '/auth/forgot-password',
      data: { email: email },
      method: 'post'
    })
  },

  resetPassword (token: string, password: string): AxiosPromise {
    return ApiService({
      url: '/auth/reset-password',
      data: {
        token: token,
        password: password
      },
      method: 'post'
    })
  },

  updatePassword (oldPassword: string, newPassword: string): AxiosPromise {
    return ApiService({
      url: '/auth/update-password',
      data: {
        oldPassword,
        newPassword
      },
      method: 'post'
    })
  },

  acceptConsent (): AxiosPromise {
    return ApiService({
      url: '/auth/accept-consent',
      method: 'patch'
    })
  },

  updatePersonalCode (personalCode: string): AxiosPromise {
    return ApiService({
      url: '/auth/save-personal-code',
      method: 'patch',
      data: {
        personalCode
      }
    })
  },

  verifyUser (token: string): AxiosPromise {
    return ApiService({
      url: '/auth/verify',
      data: { token: token },
      method: 'post'
    })
  },

  getCurrentUser (): AxiosPromise {
    return ApiService({
      url: '/auth/user',
      method: 'get'
    })
  }
}
