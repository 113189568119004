<template>
  <v-container class="my-15">
    <v-row v-if="!invitationCode">
      <v-col class="d-flex justify-center">
        <router-link :to="{name: 'Login'}">{{ $t('global.back') }}</router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card v-if="registered" max-width="400px">
          <v-card-title>{{ $t('register.success.headline') }}</v-card-title>
          <v-card-text>
            <i18n path="register.success.text" tag="label">
              <template v-slot:verify>
                <a @click="requestVerification">
                  {{ $t('register.success.verify') }}
                </a>
              </template>
            </i18n>
          </v-card-text>
        </v-card>
        <v-card v-else class="pb-4 px-2" max-width="400px" min-width="350px">
          <v-card-title>
            <v-container justify="center">
              <v-row justify="center">
                {{ $t('register.headline') }}
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-form id="login-form" ref="form">
              <v-text-field v-model="email"
                            :label="$t('register.email')"
                            :rules="emailRules"
                            prepend-icon="mdi-at">
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
              <v-text-field v-model="password"
                            :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                            :label="$t('register.password')"
                            :rules="passwordRules"
                            :type="isPasswordVisible ? 'text' : 'password'"
                            prepend-icon="mdi-lock"
                            @click:append="isPasswordVisible = !isPasswordVisible">
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
              <v-text-field v-model="passwordRepeat"
                            :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                            :label="$t('register.passwordRepeat')"
                            :rules="passwordRepeatRules"
                            :type="isPasswordVisible ? 'text' : 'password'"
                            prepend-icon="mdi-lock"
                            @click:append="isPasswordVisible = !isPasswordVisible">
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn :loading="loading" class="primary" form="login-form"
                       rounded
                       type="submit"
                       @click.stop.prevent="register">
                  {{ $t('register.submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script lang="ts">

import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'
import { User } from '@/types/Types'
import i18n from '@/plugins/i18n'
import router from '@/router'

@Component
export default class Register extends Vue {
  invitationCode = ''
  password = ''
  passwordRepeat = ''
  email = ''
  isPasswordVisible = false
  loading = false
  registered = false
  mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  allowedCharsRegex = /^[a-zA-Z0-9_-]+$/

  emailRules = [
    (v: string) => !!v || 'register.emailRequired',
    (v: string) => this.mailRegex.test(v) || 'register.emailRequired'
  ]

  usernameRules = [
    (v: string) => !!v || 'register.usernameRequired',
    (v: string) => (v && v.length <= 20) || 'home.usernameLimit',
    (v: string) => this.allowedCharsRegex.test(v) || 'home.usernameNotAllowed'
  ]

  passwordRules = [
    (v: string) => !!v || 'register.passwordRequired'
  ]

  passwordRepeatRules = [
    (v: string) => !!v || 'register.passwordRequired',
    (v: string) => this.matchesPassword(v) || 'register.passwordRepeatWrong'
  ]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  matchesPassword (password: string): boolean {
    return this.password === password
  }

  requestVerification (): void {
    this.$store.dispatch('auth/requestVerificationForCurrentUser').then(() => {
      this.loading = false

      this.$store.dispatch('notifications/showWarning', {
        text: i18n.t('notifications.emailSent').toString()
      })
    })
  }

  register (): void {
    if (this.form.validate() && this.password) {
      this.loading = true

      const user: User = {
        email: this.email,
        password: this.password,
        isTeacher: true,
        invitationCode: this.invitationCode
      }

      this.$store.dispatch('auth/register', user).then((result) => {
        this.$store.commit('auth/setCurrentUser', result)
        this.loading = false
        this.registered = true
      }).catch((error) => {
        this.loading = false

        if (error.status === 400) {
          switch (error.data.detail) {
            case 'REGISTER_USER_ALREADY_EXISTS':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.userAlreadyExists').toString()
              })
              break
            case 'DUPLICATE_KEY_ERROR':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.usernameAlreadyExists').toString()
              })
              break
            case 'NO_INVITE_CODE_GIVEN':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.noInviteCode').toString()
              })
              break
            case 'INVITE_CODE_EXPIRED':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.expiredInviteCode').toString()
              })
              break
            case 'INVALID_INVITE_CODE_GIVEN':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.invalidInviteCode').toString()
              })
              break
            default:
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.registerError').toString()
              })
              break
          }
        } else if (error.status === 404) {
          switch (error.data.detail) {
            case 'INVALID_INVITE_CODE_GIVEN':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.invalidInviteCode').toString()
              })
              break
            default:
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.registerError').toString()
              })
              break
          }
        }
      })
    }
  }

  forwardToDashboard () {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted () {
    // forward if already logged in, we may need to "refresh" the token here to be sure?
    if (this.$store.getters['auth/isAuthenticated']) {
      this.forwardToDashboard()
    }

    router.onReady(() => {
      this.invitationCode = router.currentRoute.params.invitationCode
    })
  }
}
</script>

<style scoped>

</style>
