<template>
  <v-container fluid class="my-15">
    <v-layout align-center justify-center>
      <LoadingCard v-if="loading" text="Verifying..." />
      <template v-else>
        <v-card v-if="success">
          <v-card-title>
            {{ $t('verify.success.headline') }}
          </v-card-title>
          <v-card-text>
            <i18n path="verify.success.text" tag="label">
              <template v-slot:dashboard>
                <router-link :to="{ name: 'Dashboard' }">
                  {{ $t('verify.dashboard') }}
                </router-link>
              </template>
            </i18n>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>
            {{ $t('verify.fail.headline') }}
          </v-card-title>
          <v-card-text>
            {{ $t('verify.fail.text') }}
          </v-card-text>
        </v-card>
      </template>
    </v-layout>
  </v-container>
</template>

<script lang="ts">

import Component from 'vue-class-component'
import Vue from 'vue'
import LoadingCard from '@/components/LoadingCard.vue'
import i18n from '@/plugins/i18n'
@Component({
  components: { LoadingCard }
})
export default class Verify extends Vue {
  loading = true
  success = false

  mounted (): void {
    const token = this.$route.params.token

    if (!token) {
      this.$store.dispatch('notifications/showError', {
        text: i18n.t('notifications.noTokenProvided').toString()
      })
      this.$router.push({ name: 'Public' }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.$store.dispatch('auth/verify', token).then(() => {
        this.loading = false
        this.success = true
        // "custom" logout to prevent forwarding
        this.$store.commit('auth/clearToken')
        this.$store.commit('auth/clearUser')
      }).catch((error) => {
        this.loading = false

        if (error.status === 400) {
          switch (error.data.detail) {
            case 'VERIFY_USER_TOKEN_EXPIRED':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.verificationTokenExpired').toString()
              })
              break
            case 'VERIFY_USER_BAD_TOKEN':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.verificationBadUser').toString()
              })
              break
            case 'VERIFY_USER_ALREADY_VERIFIED':
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.verificationAlreadyDone').toString()
              })
              break
            default:
              this.$store.dispatch('notifications/showError', {
                text: i18n.t('notifications.verificationError').toString()
              })
              break
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
